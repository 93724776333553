<template>
  <div>
    <v-container fluid class="py-8">
      <Notify ref="myNotify" :details="message"></Notify>
      <v-row>
        <v-col cols="12">
          <v-col cols="12">
            <v-card color="light">
              <v-app-bar color="light" class="border-left-primary">
                <v-toolbar-title>{{ $t("team.Teams") }}</v-toolbar-title>
                <button
                  v-if="
                    $store.getters['auth/str_per'].indexOf('cms-team-create') >
                    -1
                  "
                  class="py-5 mb-0 ms-auto"
                  @click="add_team()"
                >
                  <v-icon class="custome-btn btn-primary">fas fa-plus</v-icon>
                </button>
              </v-app-bar>
              <Table
                :loading="loading"
                @action="action"
                :btns="btns"
                :headers="header"
                :items="teams"
              ></Table>
            </v-card>
          </v-col>
        </v-col>
      </v-row>
      <Modal :data="modal_data"></Modal>
    </v-container>
  </div>
</template>
<script>
import Table from "../Components/Table.vue";
import Notify from "../Components/New/Notify.vue";
import Modal from "../Components/New/Modal.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("cms");
export default {
  name: "team-Page",
  components: {
    Table,
    Notify,
    Modal,
  },
  data() {
    return {
      modal_data: {
        size: "800px",
        title: "",
      },
      loading: true,
      image: null,
      message: {
        msg: null,
        type: null,
      },
      btns: [
        {
          type: "icon",
          text: "edit",
          color: "bg-gradient-success",
          icon: "mdi-pencil",
          permission: "cms-team-update",
        },
        {
          type: "icon",
          text: "destroy",
          color: "bg-gradient-danger",
          icon: "mdi-delete",
          permission: "cms-team-delete",
        },
      ],
      header: [
        { text: this.$i18n.t("group.Name"), value: "photo", align: "center" },
        // { text: this.$i18n.t("team.photo"), value: "photo", align: "center" },
        {
          text: this.$i18n.t("team.position"),
          value: "position",
          align: "center",
        },
        {
          text: this.$i18n.t("team.facebook"),
          value: "facebook",
          align: "center",
        },
        {
          text: this.$i18n.t("team.instagram"),
          value: "instagram",
          align: "center",
        },
        {
          text: this.$i18n.t("team.linkedin"),
          value: "linkedin",
          align: "center",
        },
        {
          text: this.$i18n.t("team.tweeter"),
          value: "tweeter",
          align: "center",
        },

        {
          text: this.$i18n.t("general.Action"),
          value: "btns",
          align: "center",
        },
      ],
      form_data: {
        photo: null,
        name: "",
        position: "",
        facebook: "",
        instagram: "",
        linkedin: "",
        tweeter: "",
      },
      form_style: [
        {
          col: "6",
          type: "text",
          label: this.$i18n.t("group.Name"),
          error: null,
          value_text: "name",
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
        {
          col: "6",
          type: "text",
          label: this.$i18n.t("team.position"),
          error: null,
          value_text: "position",
        },
        {
          col: "6",
          type: "text",
          label: this.$i18n.t("team.facebook"),
          error: null,
          value_text: "facebook",
        },
        {
          col: "6",
          type: "text",
          label: this.$i18n.t("team.tweeter"),
          error: null,
          value_text: "tweeter",
        },
        {
          col: "6",
          type: "text",
          label: this.$i18n.t("team.instagram"),
          error: null,
          value_text: "instagram",
        },
        {
          col: "6",
          type: "text",
          label: this.$i18n.t("team.linkedin"),
          error: null,
          value_text: "linkedin",
        },
        {
          col: "12",
          action: true,
          type: "file",
          image: true,
          accept: "image/*",
          label: this.$i18n.t("team.photo"),
          error: null,
          value_text: "photo",
          type_file: "single",
          rules: [
            (value) =>
              !value ||
              value.size < 2000000 ||
              this.$i18n.t("form.file size should be less than 2 MB"),
          ],
        },
      ],
    };
  },
  computed: {
    ...mapState(["teams"]),
  },
  methods: {
    ...mapActions(["getTeams", "destroyTeam"]),
    get_Teams() {
      this.getTeams().then(
        () => {
          this.loading = false;
        },
        (error) => {
          console.log(error);
          this.loading = false;
          this.$store.dispatch("form/setNotify", {
            msg: this.$i18n.t("general.there is problem"),
            type: "Danger",
          });
        }
      );
    },
    action(id, name, item) {
      if (name == "edit") {
        this.edit_team(item);
      } else if (name == "destroy") {
        this.destroy_team(id);
      }
    },
    add_team() {
      this.$store.commit("form/SET_DIALOG", true);
      this.modal_data.title = this.$i18n.t("team.New Team");
      this.$store.dispatch("form/setFormData", this.form_data);
      this.$store.dispatch("form/setData", this.form_style);
      this.$store.commit("form/INFO_CALL", {
        name: "cms/addTeam",
      });
    },
    edit_team(item) {
      this.$store.commit("form/SET_DIALOG", true);
      this.modal_data.title =
        this.$i18n.t("team.Edit Team") + " # " + item.name;
      this.$store.dispatch("form/setData", this.form_style);
      this.form_data.name = item.name;
      this.form_data.position = item.position;
      this.form_data.facebook = item.facebook;
      this.form_data.instagram = item.instagram;
      this.form_data.linkedin = item.linkedin;
      this.form_data.tweeter = item.tweeter;
      this.$store.dispatch("form/setFormData", this.form_data);
      this.$store.commit("form/INFO_CALL", {
        name: "cms/editTeam",
        id: item.id,
      });
    },
    destroy_team(id) {
      this.$swal({
        title: this.$i18n.t("general.Are you sure?!"),
        text: this.$i18n.t("You won't be able to revert this!"),
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: this.$i18n.t("general.Yes, delete it!"),
        cancelButtonText: this.$i18n.t("general.No, cancel!"),
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.destroyTeam(id).then(
            (response) => {
              // console.log(response)
              this.$swal.fire(
                this.$i18n.t("general.Deleted!"),
                response.data.message,
                "success"
              );
            },
            (error) => {
              console.log(error);
              this.$swal.fire(
                this.$i18n.t("general.Error"),
                this.$i18n.t("general.There error please try again"),
                "error"
              );
            }
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === this.$swal.DismissReason.cancel
        ) {
          this.$swal.fire(
            this.$i18n.t("general.Cancelled"),
            this.$i18n.t("general.Cancelled Delete"),
            "error"
          );
        }
      });
    },
  },
  mounted() {
    this.get_Teams();
    document.title = this.$i18n.t("team.Teams");
  },
};
</script>
